/* eslint-disable no-nested-ternary, complexity */

import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import get from 'lodash/get'
import { validate as validateEmail } from 'email-validator'

import { setColors } from '../../lib/colors'
import SEO from '../../components/SEO'
import Sum from '../../components/Sum'
import { Form, Loader, Amount, Tabs } from '../../_shared/components'
import { formatPrice } from '../../lib/format'

export default function Page() {
  const [error, setError] = useState(null)
  const [unitSize, setUnitSize] = useState(12)
  const [quantity, setQuantity] = useState(1)
  const [step, setStep] = useState('select')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [type, setType] = useState('private')
  const [orgnr, setOrgnr] = useState('')

  const moreThanFiddy = quantity === '50+'

  const {
    data: { organisation = null } = {},
    refetch: refetchOrganisation,
  } = useQuery(
    gql`
      query Organisation($id: String!) {
        organisation(id: $id) {
          id
          name
          ehf
        }
      }
    `,
    { variables: { id: orgnr } },
  )

  const {
    data: { getVoucherPrice: total = null } = {},
    refetch: refetchVoucherPrice,
  } = useQuery(
    gql`
      query Signup($unitSize: Int!, $quantity: Int!) {
        getVoucherPrice(unitSize: $unitSize, quantity: $quantity)
      }
    `,
    {
      variables: {
        unitSize,
        quantity: moreThanFiddy ? 1 : quantity,
      },
    },
  )

  const [createInvoice] = useMutation(gql`
    mutation CreateInvoice($returnUrl: String, $invoice: InvoiceData!) {
      createInvoice(returnUrl: $returnUrl, invoice: $invoice) {
        invoice {
          id
          amount
        }
        payment {
          ... on VippsPayment {
            redirectUrl
          }
          ... on InvoicePayment {
            foreignId
          }
        }
      }
    }
  `)

  useEffect(() => {
    refetchOrganisation({ id: orgnr })
  }, [refetchOrganisation, orgnr])

  useEffect(() => {
    refetchVoucherPrice({
      quantity: moreThanFiddy ? 1 : quantity,
      unitSize,
    })
  }, [moreThanFiddy, quantity, refetchVoucherPrice, unitSize])

  useEffect(() => {
    if (step === 'payment') {
      setColors('inverted')
      document.body.classList.add('dark')
    } else if (step === 'select' || step === 'user') {
      setColors('red')
      document.body.classList.remove('dark')
      // setColors('blue')
    } else {
      document.body.classList.remove('dark')
    }
  }, [step])

  const moveToStep = step => {
    window.location.hash = step
    setStep(step)
  }

  const handleOrgnrChange = val => setOrgnr(val.replace(/[^0-9]/g, ''))

  async function handlePayment() {
    setError(null)

    if (!email || !name) {
      setError('Fyll inn navn og e-post')
      return
    }

    const { data } = await createInvoice({
      variables: {
        returnUrl:
          type === 'business'
            ? null
            : `${window.location.origin}/gavekort/vipps`,
        invoice: {
          paymentType: type === 'business' ? 'invoice' : 'vipps',
          description: `${quantity} gavekort à ${unitSize} mnd.`,
          email,
          name,
          ...(type === 'business' ? { organisationId: organisation.id } : {}),
          lines: [
            {
              sku: 'voucher',
              title: 'Gavekort',
              unitSize,
              unitDescription: 'mnd',
              quantity,
            },
          ],
        },
      },
    })

    sessionStorage.setItem('pendingPurchaseTime', new Date().getTime())

    sessionStorage.setItem(
      'pendingPurchase',
      JSON.stringify({
        transactionId: get(data, 'createInvoice.invoice.id'),
        transactionTotal: total / 100,
        transactionProducts: [
          {
            sku: 'voucher',
            name: `Gavekort ${unitSize} mnd.`,
            category: type,
            price: total / quantity / 100,
            quantity,
          },
        ],
      }),
    )

    const redirectUrl = get(data, 'createInvoice.payment.redirectUrl')
    const foreignInvoiceId = get(data, 'createInvoice.payment.foreignId')

    if (redirectUrl) {
      setTimeout(() => {
        window.location = redirectUrl
      }, 400)
    }

    if (foreignInvoiceId) {
      window.location = `${window.location.origin}/gavekort/takk?faktura=${foreignInvoiceId}`
    }
  }

  const handleNumberUpdate = updateFunc => val => {
    if (Number.isNaN(Number(val))) {
      updateFunc(val)
      return
    }

    updateFunc(Number(val))
  }

  const pricePer = total / 100 / quantity
  const summaryDetails = `${quantity} x Gavekort ${unitSize} mnd à ${formatPrice(
    pricePer,
    type === 'business',
  )} kr`

  const SELECT_STEP = {
    title: 'Kjøp gavekort',
    items: [
      {
        id: 'businessOrPleasure',
        item: 'custom',
        children: (
          <Tabs
            active={type}
            onChange={setType}
            items={[
              { id: 'private', label: 'Privat' },
              { id: 'business', label: 'Bedrift' },
            ]}
          />
        ),
      },
      {
        id: 'sizes',
        item: 'custom',
        children: (
          <Amount
            value={quantity}
            label="Antall gavekort"
            onChange={handleNumberUpdate(setQuantity)}
            options={[
              ...new Array(50).fill(null).map((val, index) => ({
                value: index + 1,
                title: `${index + 1} stk`,
              })),
              { value: '50+', title: 'Over 50' },
            ]}
          />
        ),
      },
      ...(!moreThanFiddy
        ? [
            {
              id: 'unit',
              item: 'custom',
              children: (
                <Amount
                  value={unitSize}
                  label="Velg varighet"
                  onChange={handleNumberUpdate(setUnitSize)}
                  options={[
                    { value: 12, title: '12 mnd' },
                    { value: 6, title: '6 mnd' },
                    { value: 3, title: '3 mnd' },
                  ]}
                />
              ),
            },
            {
              id: 'summary',
              item: 'custom',
              children: (
                <Sum
                  label="Total sum"
                  value={total / 100}
                  details={summaryDetails}
                  vatDeducted={type === 'business'}
                />
              ),
            },
          ]
        : [
            {
              item: 'text',
              value: `
            Ta kontakt med oss på epost, så
            hjelper vi deg alle de praktiske tingene rundt en større bestilling.
          `,
            },
            {
              id: 'email',
              item: 'custom',
              children: (
                <div className="form__item form__item--text">
                  <a href="mailto:post@foodsteps.no?subject=Bestilling av gavekort">
                    post@foodsteps.no
                  </a>
                </div>
              ),
            },
          ]),
    ],
    onSubmit: () => moveToStep('user'),
    submitProps: { disabled: !quantity || !total },
    submit: moreThanFiddy ? null : 'Gå videre',
  }

  const USER_STEP = {
    title: 'Om deg',
    backTo: 'select',
    items: [
      {
        item: 'input',
        type: 'email',
        key: 'email',
        label: 'E-post for kvittering',
        name: 'email',
        value: email,
        onChange: setEmail,
      },
      {
        item: 'input',
        type: 'text',
        key: 'name',
        label: 'Ditt navn',
        name: 'name',
        value: name,
        onChange: setName,
      },
      ...(type === 'business'
        ? [
            {
              item: 'input',
              type: 'text',
              key: 'orgnr',
              label: 'Organisasjonsnummer',
              name: 'orgnr',
              value: orgnr,
              onChange: handleOrgnrChange,
            },
            {
              id: 'business-info',
              item: 'custom',
              children:
                orgnr.length >= 9 ? (
                  !organisation ? (
                    'Fant ikke bedriften'
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{organisation.name}</span>{' '}
                      <span>
                        {organisation.ehf ? 'EHF-faktura' : 'E-postfaktura'}
                      </span>
                    </div>
                  )
                ) : null,
            },
          ]
        : []),
      {
        id: 'login-error',
        item: 'warning',
        value: error || null,
      },
      {
        id: 'summary',
        item: 'custom',
        children: (
          <Sum label="Total sum" value={total / 100} details={summaryDetails} />
        ),
      },
    ],
    onSubmit: () => {
      setError(null)

      if (!validateEmail(email)) {
        document.querySelector('input[name="email"]').focus()
        setError('Skriv inn en gyldig e-postadresse')
        return
      }

      if (type === 'business') {
        return handlePayment()
      }

      return moveToStep('payment')
    },
    submit: type === 'business' ? 'Bekreft kjøp' : 'Til betaling',
    submitProps: {
      disabled:
        !email.length || !name.length || (type === 'business' && !organisation),
    },
  }

  const PAYMENT_STEP = {
    title: 'Betaling',
    backTo: 'user',
    disclaimer: (
      <>
        Ved å fortsette godkjenner du <Link to="/vilkar">vilkårene</Link> for
        kjøp og bruk av Foodsteps og bekrefter at du har lest{' '}
        <Link to="/personvern">personvernerklæringen</Link>.
      </>
    ),
    items: [
      {
        id: 'login-error',
        item: 'warning',
        value: error || null,
      },
    ],
    onSubmit: handlePayment,
    submitProps: { vipps: true },
    submit: 'Betal med Vipps',
  }

  const TIMEOUT_STEP = {
    title: 'Det kom ikke noen bekreftelse',
    close: true,
    items: [
      {
        item: 'text',
        value: `
          Hvis du fullførte betalingen og ser i vipps-appen din at du har betalt løser det seg
          mest sannsynlig av seg selv hvis du venter noen minutter.
        `,
      },
      {
        item: 'text',
        value: `
          Om det ikke løser seg må du ta kontakt med oss, så skal vi se på saken. Du kan laste
          ned appen mens du venter.
        `,
      },
    ],
  }

  const COMPLETED_STEP = {
    title: 'Takk!',
    close: true,
    items: [
      {
        item: 'text',
        value: `Du mottar gavekortene på epost til ${email} i løpet av noen minutter`,
      },
    ],
  }

  return (
    <>
      <SEO title="Gavekort" description="Kjøp gavekort" />
      {step === 'waiting' && (
        <Loader
          text="Venter på bekreftelse fra vipps"
          delay={0}
          type="filled"
        />
      )}
      <Form
        step={step}
        setStep={moveToStep}
        handleBack={() => navigate('/gavekort')}
        navigation
        steps={{
          select: SELECT_STEP,
          user: USER_STEP,
          payment: PAYMENT_STEP,
          waiting: { title: '' }, // after returning from vipps, pending verification
          timeout: TIMEOUT_STEP, // payment verification timed out
          completed: COMPLETED_STEP, // completed purchase
        }}
      />
    </>
  )
}
